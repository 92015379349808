<template>
  <div>
    <div class="div-input">
      <div :class="['input-custom', { error: isValidate }]">
        <div class="d-flex justify-content-center align-items-center">
          <div class="w-100 display-only">
            <input
              ref="file"
              :class="['custom-input']"
              :type="type"
              :placeholder="placeholder"
              :name="name"
              :required="required"
              :value="fileNameDisplay"
              :size="size"
              disabled
            />
            <font-awesome-icon
              icon="times-circle"
              class="text-secondary delete-icon right-top-close-icon pointer"
              v-if="fileNameDisplay"
              @click="deleteImage"
            />
          </div>
          <label class="mb-0 btn-main" :size="size">
            <input
              :multiple="isMultiple"
              type="file"
              v-on:change="handleFileChange"
              :required="required"
              :name="name"
              ref="input"
              :id="id"
              :accept="format === 'image' ? type.image : ''"
            />
            <font-awesome-icon
              icon="file-upload"
              color="white"
              class="bg-icon mr-2"
              :size="size"
            />Choose file
          </label>
        </div>
      </div>
      <p class="detail-format">{{ text }}</p>
      <div v-if="v && v.$error" class="mt-1">
        <span class="text-error" v-if="v.required == false"
          >This field can’t be empty</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    format: {
      required: true,
      type: String,
    },
    fileName: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: true,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    downloadPath: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    id: {
      required: false,
      type: String,
    },
    indexCondition: {
      required: false,
      type: Number,
    },
    indexGroup: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      file: "",
      value: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
        excel: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      error: "",
      hasError: false,
      hasImage: false,
      imgModal: null,
      msgModal: null,
      isSuccess: false,
      base64: "",
    };
  },
  computed: {
    fileNameDisplay: function () {
      if (this.fileName != null) {
        return this.fileName.split("//").pop().split("/")[2] || this.fileName;
      } else {
        return "";
      }
    },
  },
  methods: {
    handleFileChange(e) {
      this.hasError = false;
      this.error = "";
      var _validFileExtensions = this.type.file;
      if (this.format == "image") {
        _validFileExtensions = this.type.image;
      } else if (this.format == "video") {
        _validFileExtensions = this.type.video;
      } else if (this.format == "all") {
        _validFileExtensions = this.type.all;
      } else if (this.format == "pdf") {
        _validFileExtensions = this.type.pdf;
      } else if (this.format == "excel") {
        _validFileExtensions = this.type.excel;
      }
      this.value = e.target.files[0];
      if (this.value.name.includes(".csv")) {
        alert("This file type is not supported");
        return;
      }
      if (e.target.files.length) {
        if (_validFileExtensions.indexOf(this.value.type) < 0) {
          alert("This file type is not supported");
          return;
        } else if (
          this.value.size > 10000000 &&
          (this.format == "image" || this.format == "pdf")
        ) {
          alert("This file size is too large");
          return;
        } else if (
          this.value.size > 50000000 &&
          (this.format == "video" || this.format == "all")
        ) {
          alert("This file size is too large");
          return;
        } else if (this.value.size > 10000000 && this.format == "excel") {
          alert("This file size is too large");
          return;
        }
      } else {
        this.value = "";
        this.$refs.input.value = "";
        this.hasError = true;
      }
      this.$emit(
        "onFileChange",
        this.value,
        this.indexCondition,
        this.indexGroup
      );
      //this.hasImage = true;
    },
    deleteImage() {
      this.$refs.file.value = "";
      this.$refs.input.value = "";
      this.$emit("delete", this.indexGroup, this.indexCondition);
      this.hasImage = false;
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.detail-format {
  color: #9b9b9b;
  font-size: 12px;
  font-family: "Kanit-Light";
  margin-top: 3px;
  margin-bottom: 0px;
}
.bg-icon.fa-lg {
  margin: 8px 0px;
}
.div-input {
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}

input.custom-input {
  color: #16274a;
  background-color: white;
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 7px 10px;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 15px;
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  width: 120px;
  text-align: center;
  height: 37px;
  vertical-align: middle;
  cursor: pointer;
  background: #16274a;
  color: white;
  padding: 7px 10px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 10px;
}
.text-error {
  color: red;
}
@media (max-width: 767.98px) {
  .detail-format {
    font-size: 11px;
  }
}
</style>
