<template>
  <div>
    <div class="my-3">
      <div class="mb-3"><b>Field Conditons</b></div>
      <div>
        <b-row
          v-for="(field, index) of fieldList"
          :key="index + 'field'"
          class="align-items-center mb-4"
        >
          <b-col cols="4">
            <b-form-select
              v-model="field.value"
              @change="handleFieldChange($event, index)"
            >
              <b-form-select-option value="" disabled
                >-- Select Field Value --</b-form-select-option
              >
              <b-form-select-option
                :value="fieldOptions.value"
                v-for="(fieldOptions, n) of options"
                :key="'options-' + fieldOptions.value + '-' + index + '-' + n"
                :disabled="fieldOptions.disabled"
                >{{ fieldOptions.text }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="$v.fieldList.$each.$iter[index].$error"></div>
          </b-col>
          <b-col cols="6">
            <b-form-select
              v-model="field.optionsValue"
              @change="handleFieldOptions(index)"
              :class="[
                'select-custom ',
                { error: $v.fieldList.$each.$iter[index].$error },
              ]"
            >
              <b-form-select-option value="" disabled
                >-- Select Field Value --</b-form-select-option
              >
              <b-form-select-option
                :value="fieldOptions.id"
                v-for="(fieldOptions, n) of field.fieldOptions"
                :key="'options2-' + fieldOptions.name + '-' + index + '-' + n"
                :disabled="fieldOptions.disabled"
                >{{ fieldOptions.name }}</b-form-select-option
              >
            </b-form-select>
            <div v-if="$v.fieldList.$each.$iter[index].$error" class="absolute">
              <span
                class="text-error"
                v-if="!$v.fieldList.$each.$iter[index].optionsValue.required"
                >Please select
              </span>
            </div>
          </b-col>
          <b-col md="2" class="p-0">
            <b-button variant="light" @click="removeCondition(index)">
              <b-icon size="sm" icon="trash-fill" />
            </b-button>
          </b-col>
        </b-row>
        <div class="mt-3">
          <button @click.prevent="addFieldConditions" class="btn-add">
            <font-awesome-icon icon="plus-square" class="mr-2" />
            <span class="add-new">Create a New List</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, url } from "vuelidate/lib/validators";
export default {
  props: {
    campaignFieldConditions: {
      required: false,
    },
    form: {
      required: false,
    },
    v: {
      required: false,
    },
  },
  data() {
    return {
      fieldList: [],
      options: [],
    };
  },
  validations: {
    fieldList: {
      $each: {
        optionsValue: {
          required: requiredIf(function (item) {
            return item.value != "";
          }),
        },
        value: {
          required,
        },
      },
    },
  },
  watch: {
    campaignFieldConditions(val) {
      return this.createField();
    },
  },
  mounted() {},
  methods: {
    createField() {
      this.options = this.campaignFieldConditions.map((field) => {
        return { value: field.field_id, text: field.name };
      });

      let hasField = this.form.filter((el) => el.condition_type_id >= 12);
      if (hasField.length > 0) {
        hasField = hasField.map((el) => {
          let choice = this.campaignFieldConditions.find((n) =>
            n.field_choices.find((i) => i.id == el.condition_value)
          );
          let field_id = choice ? choice.field_id : "";
          choice =
            choice.field_choices.map((choice) => {
              if (hasField.find((d) => d.condition_value == choice.id)) {
                return { ...choice, disabled: true };
              } else {
                return choice;
              }
            }) || [];
          if (choice.every((el) => el.disabled)) {
            const i = this.options.findIndex((el) => el.value == field_id);
            this.options[i].disabled = true;
          }
          return {
            value: field_id,
            optionsValue: el.condition_value,
            fieldOptions: choice,
            condition_id: el.condition_type_id,
          };
        });
        this.fieldList = hasField;
      } else
        this.fieldList = [
          { value: "", optionsValue: "", fieldOptions: [], condition_id: 12 },
        ];
    },
    addFieldConditions() {
      this.fieldList.push({
        value: "",
        optionsValue: "",
        fieldOptions: [],
        condition_id: 12,
      });
    },
    handleFieldChange(val, index) {
      this.fieldList[index].optionsValue = "";
      const fieldChoice = this.campaignFieldConditions.find(
        (el) => el.field_id == val
      );

      if (fieldChoice.field_choices.length > 0) {
        this.fieldList[index].fieldOptions = fieldChoice.field_choices;
        this.handleFieldOptions(index);
      }
    },
    handleFieldOptions(index) {
      for (const x of this.fieldList[index].fieldOptions) {
        if (this.fieldList.find((el) => el.optionsValue == x.id)) {
          x.disabled = true;
        } else {
          x.disabled = false;
        }
      }
      const i = this.options.findIndex(
        (el) => el.value == this.fieldList[index].value
      );
      if (this.fieldList[index].fieldOptions.every((el) => el.disabled)) {
        this.options[i].disabled = true;
      } else {
        this.options[i].disabled = false;
      }
    },
    removeCondition(index) {
      if (index == 0 && this.fieldList.length == 1) {
        this.fieldList = [
          {
            value: "",
            optionsValue: "",
            fieldOptions: [],
            condition_id: 12,
          },
        ];
      } else {
        this.fieldList.splice(index, 1);
        for (const n of this.options) {
          let c = this.campaignFieldConditions.find(
            (el) => el.field_id == n.value
          );

          n.disabled =
            this.fieldList.filter((x) => x.value == n.value).length == c.length;
        }
      }
    },
  },
};
</script>

<style>
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}

.absolute {
  position: absolute;
}
</style>
